import { nextTick } from "vue";

export const pushDataLayerEvent = (
  eventName: string,
  data: Record<string, unknown>,
) => {
  if (import.meta.server) {
    return;
  }

  nextTick(() => {
    window.dataLayer = window.dataLayer ?? [];

    window.dataLayer.push({
      event: eventName,
      ...data,
    });
  });
};
