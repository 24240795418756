import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";
import type { TypeDownloadSkeleton } from "./TypeDownload";
import type { TypeExternalAuthorSkeleton } from "./TypeExternalAuthor";
import type { TypePageSkeleton } from "./TypePage";
import type { TypeProjectSkeleton } from "./TypeProject";
import type { TypeStaffSkeleton } from "./TypeStaff";
import type { TypeThemeSkeleton } from "./TypeTheme";
import type { TypeTopicSkeleton } from "./TypeTopic";

export type TypeReportFields = {
  title?: EntryFieldTypes.Symbol;
  subtitle?: EntryFieldTypes.Symbol;
  slug?: EntryFieldTypes.Symbol;
  parent?: EntryFieldTypes.EntryLink<TypePageSkeleton>;
  headerImage?: EntryFieldTypes.AssetLink;
  date?: EntryFieldTypes.Date;
  project?: EntryFieldTypes.EntryLink<TypeProjectSkeleton>;
  themeOrTopic?: EntryFieldTypes.EntryLink<TypeThemeSkeleton | TypeTopicSkeleton>;
  image: EntryFieldTypes.AssetLink;
  downloads?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeDownloadSkeleton>>;
  author?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeExternalAuthorSkeleton | TypeStaffSkeleton>>;
  content?: EntryFieldTypes.RichText;
  relatedContent?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeReportSkeleton>>;
  metaTitle?: EntryFieldTypes.Symbol;
  metaDescription?: EntryFieldTypes.Symbol;
};

export type TypeReportSkeleton = EntrySkeletonType<TypeReportFields, "report">;
export type TypeReport<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypeReportSkeleton, Modifiers, Locales>;

export function isTypeReport<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypeReport<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === "report";
}
