import type { Entry } from "contentful";

export default (entry: Entry) => {
  const routes = useRoutes();

  const url = routes.value.find((route) =>
    route.sysId.includes(entry.sys.id),
  )?.path;

  if (!url) {
    throw new Error(`Route for ${entry.sys.id} not found`);
  }

  return url;
};
