import { pushDataLayerEvent } from "~/lib/client-data-layer";

export default defineNuxtRouteMiddleware((to) => {
  if (import.meta.server) {
    return;
  }

  pushDataLayerEvent("evNavigate", {
    ...(useUser().value
      ? {
          user: {
            salesforce: {
              contact: {
                id: useUser().value?.salesforce.contact.id,
                country: useUser().value?.salesforce.contact.country,
              },
              account: {
                id: useUser().value?.salesforce.account.id,
                name: useUser().value?.salesforce.account.name,
                region: useUser().value?.salesforce.account.country,
                subType: useUser().value?.salesforce.account.financialService,
                aum: useUser().value?.salesforce.account.aum,
              },
            },
          },
        }
      : {}),
    title:
      useRoutes().value.find((route) => route.path === to.path)?.title ?? "",
    navigateUrl: to.fullPath,
  });
});
