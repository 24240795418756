<script lang="ts" setup>
import { type TypeBannerVideoPromo, isTypeArticle, isTypeReport } from "~/types/contentful";
import type { TypeVimeo } from "~/types/TypeVimeo";

const props = defineProps<{
  fields: TypeBannerVideoPromo<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
}>();

const { data: vimeo } = await useLazyFetch<TypeVimeo>("https://vimeo.com/api/oembed.json?url=" + encodeURIComponent(props.fields.video?.fields.url ?? ""));

const subtitle = computed(() => props.fields.contentLink && (isTypeArticle(props.fields.contentLink) || isTypeReport(props.fields.contentLink)) && props.fields.contentLink.fields.subtitle ? props.fields.contentLink.fields.subtitle : vimeo.value?.title);
</script>

<template>
  <Container class="banner-video-promo py-4 md:py-6">
    <NuxtLink
      v-if="fields.video && fields.contentLink"
      :to="`/resources/video-hub/player/${fields.video.fields.slug}`"
      class="relative flex flex-col justify-between font-normal"
    >
      <NuxtImg
        v-if="fields.video.fields.videoThumbnail?.fields.file?.url"
        class="max-h-[500px] w-full object-cover object-center"
        :src="fields.video.fields.videoThumbnail.fields.file.url"
        :alt="fields.video.fields.videoThumbnail.fields.title"
        sizes="sm:100vw md:100vw lg:100vw xl:100vw xxl:100vw"
        :height="500"
        fit="contain"
        loading="lazy"
      />

      <div
        class="pointer-events-none absolute bottom-7 right-8 flex items-center justify-center rounded bg-light-royal-blue"
      >
        <span class="cursor-pointer px-5 py-[12px] font-bold text-white">Watch Now</span>
      </div>

      <section
        class="absolute flex grow flex-col justify-between rounded-b p-4 md:px-6 md:pt-8"
      >
        <UiVideoInfo
          v-if="fields.video"
          :download="fields.video"
          :duration="vimeo?.duration"
          :show-date="true"
        />

        <div>
          <h2 class="text-white md:w-2/3">
            {{ fields.contentLink?.fields.title || fields.video.fields.title }}
          </h2>

          <p
            v-if="subtitle"
            class="mt-3 font-normal text-white md:w-2/3 md:pr-5"
          >
            {{ subtitle }}
          </p>
        </div>
      </section>
    </NuxtLink>
  </Container>
</template>
