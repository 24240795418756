export const formatDate = (date: Date, noYear = false) =>
  [
    date.getDate(),
    date.toLocaleDateString("en-GB", {
      month: "long",
    }),
    ...(noYear ? [] : [date.getFullYear()]),
  ].join(" ");

export const formatTime = (date: Date) =>
  `${String(date.getHours()).padStart(2, "0")}:${String(
    date.getMinutes(),
  ).padStart(2, "0")}`;
