<script lang="ts" setup>
// This component actually outputs Pages and Articles, and should be renamed
import {
  type TypeCardBlockProject,
  isTypePage,
  isTypeArticle,
  isTypeExternalPage,
} from "~/types/contentful";

defineProps<{
  fields: TypeCardBlockProject<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
}>();
</script>

<template>
  <Container class="card-block--event py-4 md:py-6">
    <div class="flex flex-wrap gap-4">
      <div
        v-if="fields.displaySectionTitle"
        :id="fields?.title?.replaceAll(' ', '-').toLowerCase()"
        class="w-full"
      >
        <TextSectionTitle>{{ fields.title }}</TextSectionTitle>
      </div>
      <div class="projects w-full">
        <div
          v-for="pageOrArticle in fields.project?.filter(
            (item): item is NonNullable<typeof item> => !!item,
          )"
          :key="pageOrArticle.sys.id"
          class="grid grid-cols-12 gap-4 border-t-2 border-ui-grey2 py-4 md:py-5"
        >
          <div class="col-span-12 md:col-span-8 lg:col-span-7">
            <NuxtLink :to="getUrl(pageOrArticle)">
              <h3 class="mb-3 md:mb-4">
                {{ pageOrArticle.fields.title }}
              </h3>
            </NuxtLink>
            <p
              v-if="isTypePage(pageOrArticle)"
              class="max-md:mb-0"
            >
              {{ pageOrArticle.fields.excerpt }}
            </p>
          </div>
          <div
            class="col-span-6 md:col-span-3 md:col-end-13 lg:col-span-2 lg:col-end-13"
          >
            <NuxtImg
              v-if="
                isTypePage<'WITHOUT_UNRESOLVABLE_LINKS', 'en-GB'>(
                  pageOrArticle,
                ) && pageOrArticle.fields.thumbnail?.fields.file
              "
              :src="pageOrArticle.fields.thumbnail.fields.file.url"
              :alt="pageOrArticle.fields.thumbnail.fields.title"
              :width="300"
              :height="300"
              loading="lazy"
            />
            <NuxtImg
              v-else-if="
                (isTypeArticle<'WITHOUT_UNRESOLVABLE_LINKS', 'en-GB'>(
                  pageOrArticle,
                )
                  || isTypeExternalPage<'WITHOUT_UNRESOLVABLE_LINKS', 'en-GB'>(
                    pageOrArticle,
                  ))
                  && pageOrArticle.fields.image?.fields.file
              "
              :src="pageOrArticle.fields.image.fields.file.url"
              :alt="pageOrArticle.fields.image.fields.title"
              :width="300"
              :height="300"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  </Container>
</template>
