<script lang="ts" setup>
import type { TypeBlockNewsletter } from "~/types/contentful";

defineProps<{
  fields: TypeBlockNewsletter<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
}>();

const sent = ref(false);
</script>

<template>
  <div class="gradient min-h-[80px] bg-level-standard" />
  <Container class="bg-level-standard py-6">
    <div class="gap-4 md:grid md:grid-cols-2">
      <template v-if="sent">
        <div>
          <h2 class="mb-3">
            Thanks for signing up
          </h2>
          <p class="text-lg">
            Please check your email.
          </p>
        </div>
      </template>
      <template v-else>
        <div>
          <h2 class="mb-3">
            Stay up to date
          </h2>
          <p class="text-lg">
            Sign up to our newsletter to get the latest on our award-winning ESG
            research, insights & more.
          </p>
        </div>
        <div v-if="fields.formEndpoint">
          <FormNewsletter
            :form-endpoint="fields.formEndpoint"
            @sent="sent = true"
          />
        </div>
      </template>
    </div>
  </Container>
</template>

<style lang="scss" scoped>
.gradient {
  background: linear-gradient(
    to top,
    transparent 0 5px,
    #f3f5f7 5px 11px,
    transparent 11px 17px,
    #f3f5f7 17px 23px,
    transparent 23px 29px,
    #f3f5f7 29px 34px,
    transparent 34px 42px,
    #f3f5f7 42px 46px,
    transparent 46px 54px,
    #f3f5f7 54px 57px,
    transparent 57px 67px,
    #f3f5f7 67px 69px,
    transparent 69px 79px,
    #f3f5f7 79px 80px
  );
}
</style>
