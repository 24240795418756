<script lang="ts" setup>
import type { TypeButton } from "~/types/TypeButton";
import {
  isTypeTheme,
  isTypeTopic,
  type TypeCarouselColumn,
} from "~/types/contentful";

defineProps<{
  fields: TypeCarouselColumn<
    "WITHOUT_UNRESOLVABLE_LINKS",
    "en-GB"
  >["fields"] & { button: TypeButton[] };
}>();
</script>

<template>
  <div class="block--promo-columns bg-[#f2f5f7] relative py-4 md:py-6">
    <Container>
      <div
        v-if="fields.displaySectionTitle"
        class="w-full pb-3"
      >
        <TextSectionTitle bg-color="#f2f5f7">{{ fields.title }}</TextSectionTitle>
      </div>
    
      <div class="grid md:grid-cols-2 xl:grid-cols-4 gap-x-4">
        <template
          v-for="promo in fields.promos"
          :key="promo?.sys.id"
        >
          <NuxtLink
            v-if="promo"
            :to="(promo.fields.button as unknown as TypeButton[])?.[0]?.url"
          >
            <div class="grid grid-cols-4 min-h-[400px]">
              <NuxtImg
                v-if="promo.fields.backgroundImage"
                :src="promo.fields.backgroundImage.fields.file?.url"
                :alt="promo.fields.backgroundImage.fields.title"
                :width="511"
                :height="734"
                class="col-span-1 h-full max-w-fit"
                loading="lazy"
              />

              <div class="col-span-3 px-4 pb-4 pt-5 flex flex-col justify-between bg-white">
                <h3>
                  {{ promo.fields.title }}
                </h3>

                <div>
                  <div
                    v-for="themetopic in promo.fields.themetopic"
                    :key="themetopic?.fields.title"
                  >
                    <TextBadge
                      class="mb-2"
                      :colour="
                        themetopic && isTypeTheme(themetopic)
                          ? themetopic.fields.colour
                          : themetopic && isTypeTopic(themetopic)
                            ? themetopic.fields.theme?.fields.colour
                            : undefined
                      "
                    >
                      {{ themetopic?.fields.title }}
                    </TextBadge>
                  </div>
                </div>
                
                <button class="-ml-[32px] btn btn--secondary">
                  {{ (promo.fields.button as unknown as TypeButton[])?.[0]?.title }}
                </button>
              </div>
            </div>
            
          </NuxtLink>
        </template>
      </div>
    </Container>
  </div>
</template>