import validate from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/validate.js";
import _10_45redirects_45global from "/opt/build/repo/middleware/10.redirects.global.ts";
import _15_45external_45page_45global from "/opt/build/repo/middleware/15.external-page.global.ts";
import _20_45load_45config_45global from "/opt/build/repo/middleware/20.load-config.global.ts";
import _25_45load_45routes_45global from "/opt/build/repo/middleware/25.load-routes.global.ts";
import _26_45article_45redirects_45global from "/opt/build/repo/middleware/26.article-redirects.global.ts";
import _30_45load_45user_45global from "/opt/build/repo/middleware/30.load-user.global.ts";
import _40_45load_45content_45global from "/opt/build/repo/middleware/40.load-content.global.ts";
import _45_45set_45layout_45global from "/opt/build/repo/middleware/45.set-layout.global.ts";
import _50_45data_45layer_45global from "/opt/build/repo/middleware/50.data-layer.global.ts";
import manifest_45route_45rule from "/opt/build/repo/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _10_45redirects_45global,
  _15_45external_45page_45global,
  _20_45load_45config_45global,
  _25_45load_45routes_45global,
  _26_45article_45redirects_45global,
  _30_45load_45user_45global,
  _40_45load_45content_45global,
  _45_45set_45layout_45global,
  _50_45data_45layer_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/opt/build/repo/middleware/auth.ts"),
  "no-auth": () => import("/opt/build/repo/middleware/no-auth.ts")
}