<script lang="ts" setup>
const props = defineProps<{
  id?: string;
  name?: string;
  value?: string;
  checked?: boolean;
  required?: boolean;
  disabled?: boolean;
  blockIndex?: number;
}>();

const slots = useSlots();

const emit = defineEmits(["onCheckboxChange"]);

const isChecked = ref(false);

onMounted(() => (isChecked.value = props.checked));

watch(
  () => props.checked,
  (updatedPropsChecked) => (isChecked.value = updatedPropsChecked),
);

watch(isChecked, (updatedChecked) => {
  emit("onCheckboxChange", updatedChecked, props.id, props.blockIndex);
});
</script>

<template>
  <div
    class="checkbox"
    :aria-checked="props.checked"
  >
    <input
      :id="props.id"
      v-model="isChecked"
      type="checkbox"
      :name="props.name"
      :value="props.value || '1'"
      :required="required"
      :disabled="disabled"
    />
    <label
      v-if="slots.default"
      :for="props.id"
    ><slot /></label>
  </div>
</template>

<style lang="scss" scoped>
.checkbox {
  transition: opacity 0.1s ease-out;
  display: inline-flex;
  align-items: center;
  margin: 6px 0;
  width: fit-content;
  cursor: pointer;

  &:hover {
    opacity: 0.8 !important;
  }

  label {
    padding-left: 8px;
    cursor: pointer;
    user-select: none;
  }

  input[type="checkbox"] {
    transition:
      background-color 0.1s ease-out,
      border-color 0.1s ease-out;
    display: grid;
    place-content: center;

    @apply border-2 border-solid border-ui-grey2 bg-white;

    width: 20px;
    height: 20px;
    border-radius: 2px;
    appearance: none;
    cursor: pointer;
  }

  input[type="checkbox"]::before {
    transition: transform 0.2s ease-out;
    content: "";
    width: 12px;
    height: 12px;
    transform: scale(0);

    @apply bg-light-royal-blue;
  }

  input[type="checkbox"]:checked {
    @apply border-light-royal-blue bg-transparent;
  }

  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }
}
</style>
