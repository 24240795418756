<script lang="ts" setup>
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import type { TypeBlockStatistics } from "~/types/contentful";

defineProps<{
  fields: TypeBlockStatistics<
    "WITHOUT_UNRESOLVABLE_LINKS",
    "en-GB"
  >["fields"] & { statistics: { key: string; value: string }[] };
}>();
</script>

<template>
  <Container class="block-statistics py-4 md:py-6">
    <div class="grid grid-cols-12 gap-4">
      <div
        v-if="fields.displaySectionTitle"
        class="col-span-12"
      >
        <TextSectionTitle>{{ fields.title }}</TextSectionTitle>
      </div>
      <div class="col-span-12 lg:col-span-6">
        <div class="wysiwyg">
          <RichTextRenderer :document="fields.text" />
        </div>
      </div>
      <div class="col-span-12 lg:col-span-6">
        <div class="grid grid-cols-2 gap-3 md:gap-4">
          <div
            v-for="(statistic, index) in fields.statistics"
            :key="index"
            class="lg:col-span-1"
          >
            <div class="h-full bg-light-royal-blue/20 p-3 md:p-4">
              <h4>{{ statistic.key }}</h4>
              {{ statistic.value }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </Container>
</template>
