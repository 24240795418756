<script lang="ts" setup>
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import type { TypeBlockStatement } from "~/types/contentful";

defineProps<{
  fields: TypeBlockStatement<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
}>();
</script>

<template>
  <Container class="block-statement py-4 md:py-6">
    <div
      v-if="fields.displaySectionTitle"
      class="col-span-12"
    >
      <TextSectionTitle>{{ fields.title }}</TextSectionTitle>
    </div>

    <div
      class="content wysiwyg grid grid-cols-12 gap-4 rounded-[2px] bg-level-standard py-6"
    >
      <div
        class="col-span-12 px-4 md:col-span-10 md:col-start-2 xl:col-span-6 xl:col-start-4"
      >
        <RichTextRenderer :document="fields.content" />
      </div>
    </div>
  </Container>
</template>

<style lang="scss" scoped>
.content:deep(p) {
  margin-bottom: 24px;
}
</style>
