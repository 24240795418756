export default defineNuxtPlugin(() => {
  useRouter().options.scrollBehavior = (_to, _from, savedPosition) => {
    return new Promise((resolve) => {
      if (savedPosition) {
        resolve(savedPosition);
      } else {
        resolve({ top: 0 });
      }
    });
  };
});
