<script lang="ts" setup>
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import type { TypeArticleBlockComparison } from "~/types/contentful";

defineProps<{
  fields: TypeArticleBlockComparison<
    "WITHOUT_UNRESOLVABLE_LINKS",
    "en-GB"
  >["fields"];
}>();
</script>

<template>
  <div class="article-block-comparison -mx-4 py-4">
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12 p-0 md:col-span-6">
        <div class="wysiwyg rounded border border-ui-grey2 p-4">
          <h3 class="!mb-2 !mt-1">
            {{ fields.publicReportTitle || "Public Report" }}
          </h3>
          <div class="mb-4 flex text-dark-blue-grey-two">
            <TextBadge>Non-members</TextBadge>
            <TextBadge class="ms-2">
              Members
            </TextBadge>
          </div>
          <RichTextRenderer :document="fields.publicReport" />
          <UiDownloadLink
            v-if="fields.publicDownload"
            class-list="btn btn--icon btn--download mt-3 w-full"
            :download="fields.publicDownload"
          >
            {{
              fields.publicDownload.fields.shortTitle
                || fields.publicDownload.fields.title
            }}
          </UiDownloadLink>
        </div>
      </div>

      <div class="col-span-12 md:col-span-6">
        <div
          class="wysiwyg public rounded bg-dark-blue-grey-two p-4 text-white"
        >
          <h3 class="!mb-2 !mt-1">
            {{ fields.membersReportTitle || "Members' Report" }}
          </h3>
          <div class="mb-4 flex text-dark-blue-grey-two">
            <TextBadge>Members</TextBadge>
          </div>
          <RichTextRenderer :document="fields.membersReport" />
          <UiDownloadLink
            v-if="fields.memberDownload"
            class-list="btn btn--icon btn--download mt-3 w-full"
            :download="fields.memberDownload"
            :disable-pardot="true"
          >
            {{
              fields.memberDownload.fields.shortTitle
                || fields.memberDownload.fields.title
            }}
            <template #locked>
              <NuxtLink
                to="/login"
                class="btn btn--icon btn--locked mt-3 w-full"
              >
                {{
                  fields.memberDownload.fields.shortTitle
                    || fields.memberDownload.fields.title
                }}
              </NuxtLink>
            </template>
          </UiDownloadLink>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.wysiwyg {
  &:deep(ul),
  &:deep(li) {
    padding-left: 0;
    list-style: none;
  }

  &:deep(li p) {
    background-image: url("~/assets/icons/static-fill/tick--dark.svg");
  }

  &:deep(li p),
  &:deep(p) {
    background-repeat: no-repeat;
    background-position: left -3px top;
    margin-bottom: 12px !important;
  }

  &:deep(p) {
    padding-left: 30px;
    background-image: url("~/assets/icons/static-fill/cross--grey.svg");
  }

  &.public {
    &:deep(li p) {
      background-image: url("~/assets/icons/static-fill/tick--white.svg");
    }
  }

  &:deep(a) {
    justify-content: flex-start;
    width: 100%;
  }
}
</style>
