import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";
import type { TypeThemeSkeleton } from "./TypeTheme";

export type TypeTopicFields = {
  title: EntryFieldTypes.Symbol;
  theme: EntryFieldTypes.EntryLink<TypeThemeSkeleton>;
};

export type TypeTopicSkeleton = EntrySkeletonType<TypeTopicFields, "topic">;
export type TypeTopic<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypeTopicSkeleton, Modifiers, Locales>;

export function isTypeTopic<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypeTopic<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === "topic";
}
