<script lang="ts" setup>
import type { EntrySys } from "contentful";
import { isActiveNav } from "~/lib/routing.js";
import type { TypeNavigationMain } from "~/types/contentful";

const { isDesktop } = useResponsive();

const props = defineProps<{
  fields: TypeNavigationMain<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
  sys: EntrySys;
  open: boolean;
}>();

// We source nav items from here, instead of through contentful's resolver,
// so we have smaller payloads
const { data: navItems } = await useFetch("/api/nav", {
  query: { id: props.sys.id },
});

const visibleDropdown = ref<number | null>(null);

const route = useRoute();

watch(route, () => (visibleDropdown.value = null));

const emit = defineEmits(["onClickOutside", "search"]);

const onBackClick = () => {
  if (visibleDropdown.value !== null) visibleDropdown.value = null;
};

const onClickOutside = (event: Event) => {
  if ((event.target as HTMLElement).id !== "burger") emit("onClickOutside");
};

const onNavigate = () => {
  emit("onClickOutside");

  visibleDropdown.value = null;
};

const onMouseOver = (index: number) => {
  if (isDesktop.value) visibleDropdown.value = index;
};
const onMouseLeave = () => {
  if (isDesktop.value) visibleDropdown.value = null;
};
</script>

<template>
  <Container class="navigation--main">
    <nav
      v-click-outside="onClickOutside"
      class="absolute right-0 z-50 min-w-[375px] items-center justify-center border-ui-grey2 text-sm max-lg:h-[calc(100vh-55px)] max-lg:border-t max-lg:bg-white max-lg:shadow-2xl lg:relative lg:flex lg:px-5 lg:py-2.5"
      :class="open ? '' : 'max-lg:pointer-events-none max-lg:opacity-0'"
    >
      <div class="p-3 lg:hidden">
        <form
          action="/search"
          method="get"
          class="relative flex items-center"
        >
          <NuxtIcon
            class="absolute mx-2"
            name="fairr:search"
            size="24"
          />
          <input
            type="search"
            name="q"
            placeholder="Search FAIRR"
            class="pl-[2.5rem]"
            @focus="(event: Event) => emit('search', event)"
          />
        </form>
      </div>

      <div class="dividers px-3 lg:hidden">
        <hr class="mb-[2px] mt-0" />
        <hr class="mb-[2px] mt-0" />
        <hr class="mb-[2px] mt-0" />
      </div>
      <div
        v-for="(item, index) in navItems"
        :key="index"
        class="mt-3 flex lg:justify-center"
        :class="{
          active: isActiveNav(item, route),
        }"
        @mouseover="onMouseOver(index)"
        @mouseleave="onMouseLeave"
      >
        <NuxtLink
          v-if="'link' in item"
          :to="item.link"
          class="cursor-pointer px-3"
          @click="onNavigate"
        >
          <span>{{ item.label }}</span>
        </NuxtLink>
        <template v-else-if="'primaryLinks' in item">
          <span
            class="menu-item -mb-3 cursor-pointer px-3 pb-3 max-lg:flex max-lg:w-full max-lg:justify-between"
            @click="() => (visibleDropdown = !isDesktop ? index : null)"
          >
            <span>{{ item.name }}</span>
            <NuxtIcon
              name="fairr:arrow"
              class="rotate-[270deg] max-lg:block lg:!hidden"
              size="16"
            />
          </span>
          <Transition name="fade">
            <div
              v-if="visibleDropdown === index"
              class="absolute top-0 z-40 flex w-[313px] flex-col gap-3 rounded-sm bg-white p-[20px] max-lg:top-0 max-lg:h-screen max-lg:w-[375px] lg:top-full"
            >
              <button
                class="items-center gap-1 font-semibold max-lg:flex lg:!hidden"
                @click="onBackClick"
              >
                <NuxtIcon
                  name="fairr:arrow"
                  size="16"
                  class="rotate-90"
                />
                back
              </button>
              <div v-if="item.primaryLinks.length">
                <NuxtLink
                  v-for="(primaryLink, index2) in item.primaryLinks"
                  :key="index2"
                  :to="primaryLink.link"
                  class="block w-full rounded-sm bg-light-royal-blue/10 p-2"
                  @click="onNavigate"
                >
                  {{ primaryLink.label }}
                </NuxtLink>
                <hr class="mb-0.5 mt-3 border-t border-dark-blue-grey-two/40" />
                <hr class="my-0.5 border-t border-dark-blue-grey-two/40" />
                <hr class="my-0.5 border-t border-dark-blue-grey-two/40" />
              </div>
              <template
                v-for="(groupOrLink, index2) in item.items"
                :key="index2"
              >
                <div
                  v-if="'links' in groupOrLink"
                  class="flex flex-col gap-2"
                >
                  <span class="text-dark-blue-grey-two/50">{{
                    groupOrLink.title
                  }}</span>
                  <NuxtLink
                    v-for="(link, index3) in groupOrLink.links"
                    :key="index3"
                    :to="link.link"
                    class="flex min-h-[32px] w-full items-center"
                    @click="onNavigate"
                  >
                    <NuxtImg
                      v-if="link.icon"
                      :src="link.icon"
                      :width="32"
                      loading="lazy"
                      alt=""
                      class="mr-2 w-[32px] rounded"
                    />
                    {{ link.label }}
                  </NuxtLink>
                </div>
                <NuxtLink
                  v-else-if="'link' in groupOrLink"
                  :to="groupOrLink.link"
                  class="flex min-h-[32px] w-full items-center"
                  @click="onNavigate"
                >
                  <NuxtImg
                    v-if="groupOrLink.icon"
                    :src="groupOrLink.icon"
                    :width="32"
                    loading="lazy"
                    alt=""
                    class="mr-2 w-[32px] rounded"
                  />
                  {{ groupOrLink.label }}
                </NuxtLink>
              </template>
            </div>
          </Transition>
        </template>
      </div>
    </nav>
  </Container>
</template>

<style lang="scss" scoped>
nav {
  a {
    @apply font-medium;
  }

  > div {
    > a,
    > span {
      @apply text-center font-medium;

      &:last-child {
        @apply max-md:pr-3;
      }

      span:first-child {
        @apply block border-b-4 border-b-transparent pb-2;

        .inline-page & {
          @apply border-b-4 border-b-ui-grey4;
        }
      }
    }

    > div {
      box-shadow: 0 0 20px 0 rgb(20 21 63 / 10%);
    }

    &:hover,
    &.active {
      > a,
      > span {
        @apply lg:text-light-royal-blue;

        span:first-child {
          @apply lg:border-b-light-royal-blue;
        }
      }
    }
  }

  [type="search"] {
    @apply bg-white/20 text-white;
  }
}

.dividers hr {
  border-top-width: 1px;
}
</style>
