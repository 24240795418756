<script lang="ts" setup>
import { filterArrayDeep, updateFiltersChecked } from "~/lib/filter";
import type { TypeTableFilterable } from "~/types/contentful";
import { sortByKey } from "~/lib/sort";
import { pushDataLayerEvent } from "~/lib/client-data-layer";

const props = defineProps<{
  fields: TypeTableFilterable<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
}>();

type Item = Record<string, string>;

const dataLayer = reactive<{
  name?: string;
  project?: string;
  filter?: {
    group: string;
    value: string;
  };
  search?: string;
  dropdown1?: string;
  dropdown2?: string;
}>({
  name: props.fields.name,
  project: props.fields.dataSource?.fields.project?.fields.name,
});

const { data: items } = await useLazyFetch<{ data: Item[] }>(
  "/api/data-source",
  {
    query: { id: props.fields.dataSource?.sys.id },
  },
);

const allItems = computed(() =>
  items.value?.data.length ? items.value.data : [{ Company: "Test Company" }],
);

const dataFields = computed<string[]>(() =>
  Object.keys(items.value?.data[0] ?? ["Company"]),
);

const csvFilter = "Filter_";
const csvFilterFields = computed(() =>
  dataFields.value.filter((f) => f.includes(csvFilter)),
);

const filterFields = computed(() => [
  ...["Country", "Protein Type"],
  ...csvFilterFields.value,
]);
const filtersChecked = ref<{ field: string; id: string }[]>([]);

const onFilterChange = (checked: boolean, id: string, index: number) => {
  const field = filterFields.value[index] ?? "";

  updateFiltersChecked(filtersChecked.value, field, checked, id);

  dataLayer.filter = {
    group: field,
    value: id,
  };
  pushDataLayerEvent("evTableFilterableFilter", dataLayer);
};

const filteredCompanies = computed(() =>
  filterArrayDeep(allItems.value, filtersChecked.value, filterFields.value),
);

const factors = computed(() => [
  ...new Set(items.value?.data.map((company) => company.Factor)),
].filter((factor): factor is NonNullable<typeof factor> => !!factor));
const factorSelected = ref<string>();

const factorTopics = computed(() => [
  ...new Set(
    items.value?.data
      .filter((c) => c.Factor === factorSelected.value)
      .map((company) => company.Topic),
  ),
].filter((topic): topic is NonNullable<typeof topic> => !!topic));
const topicSelected = ref<string>();

const companiesByFactor = computed(() => {
  if (!factorSelected.value) return filteredCompanies.value;
  return filteredCompanies.value.filter(
    (company) => company.Factor === factorSelected.value,
  );
});

const companiesByTopic = computed(() => {
  if (!topicSelected.value) return companiesByFactor.value;
  return filteredCompanies.value.filter(
    (company) => company.Topic === topicSelected.value,
  );
});

const sortByField = ref("");
const sortAscending = ref(true);

const itemsSorted = computed(() => {
  if (sortByField.value === "") return companiesByTopic.value;
  const itemz = sortByKey(companiesByTopic.value, sortByField.value);
  return sortAscending.value ? itemz : itemz.reverse();
});

const input = ref("");

watch(input, (value) => {
  dataLayer.search = value;
  pushDataLayerEvent("evTableFilterableSearch", dataLayer);
});

const itemsSortedSearched = computed(() =>
  itemsSorted.value.filter((item) => {
    for (const field of dataFields.value) {
      if (
        String(item[field])
          .toLowerCase()
          .includes(input.value.toLowerCase())
      )
        return true;
    }

    return false;
  }),
);

const filterPanelBlocks = computed(() =>
  filterFields.value.map((field) => {
    let values = [
      ...new Set(
        items.value?.data.map((company) =>
          String(company[field]),
        ),
      ),
    ];

    if (field.includes(csvFilter)) {
      values = [...new Set(values.join(", ").split(/,\s+/))].sort((a, b) =>
        a.localeCompare(b),
      );
    }
    return values;
  }),
);

const onHeaderClick = (field: string) => {
  if (sortByField.value === field) {
    sortAscending.value = !sortAscending.value;
  } else {
    sortAscending.value = true;
    sortByField.value = field;
  }
};
</script>

<template>
  <Container>
    <h2 class="mt-3">
      {{ fields.name }}
    </h2>

    <div class="mt-4 flex items-center justify-between">
      <div class="flex items-center md:w-[70%]">
        <div
          class="mb-3 mr-2 flex h-5 w-full items-center rounded-sm border border-ui-grey2 md:mb-0 md:max-w-[700px]"
        >
          <NuxtIcon
            class="px-2"
            name="fairr:search"
            size="24"
          />
          <input
            v-model="input"
            class="w-full border-none focus:outline-none"
            type="text"
            placeholder="Search for..."
          />
        </div>

        <UiDropdown
          v-if="dataFields.includes('Factor')"
          class="mr-2 min-w-[200px] max-w-[200px]"
          label="Factor"
          :items="factors"
          :select-none="true"
          @select="
            factorSelected = $event;
            topicSelected = '';
            dataLayer.dropdown1 = $event;
            delete dataLayer.dropdown2;
            pushDataLayerEvent('evTableFilterableDropdown1', dataLayer);
          "
        />

        <UiDropdown
          v-if="dataFields.includes('Topic')"
          id="topic-select"
          :class="{ 'pointer-events-none opacity-50': !factorSelected }"
          label="Topic"
          :items="factorTopics"
          :select-none="true"
          @select="
            topicSelected = $event;
            dataLayer.dropdown2 = $event;
            pushDataLayerEvent('evTableFilterableDropdown2', dataLayer);
          "
        />
      </div>

      <UiFilterPanel
        :titles="filterFields"
        :blocks="filterPanelBlocks"
        scroll-on-open
        @on-filter-change="onFilterChange"
      />
    </div>

    <table class="mt-3 align-top">
      <thead>
        <tr>
          <td
            v-for="field in dataFields.filter((f) => !f.includes(csvFilter))"
            :key="field"
            class="relative cursor-pointer select-none rounded-sm border-x-8 border-white bg-level-standard px-2 py-[12px] font-body text-sm font-medium uppercase leading-[1.17] tracking-[1.5px] first-of-type:border-l-0 last-of-type:border-r-0"
            :class="{
              '!bg-pale-blue2': sortByField === field,
              'w-[112px]': field === 'Factor',
              'w-[110px]': field === 'Topic',
              'w-[30%]': field === 'Investors',
              'w-[72px]': field === 'Date',
            }"
            @click="onHeaderClick(field)"
          >
            {{ field }}
            <div
              v-if="sortByField === field"
              class="absolute right-px top-[13px]"
              :class="{ 'rotate-180': sortAscending }"
            >
              <NuxtIcon name="fairr:sort" />
            </div>
          </td>
        </tr>
      </thead>
      <tbody v-if="items">
        <tr
          v-for="(item, i) in itemsSortedSearched"
          :key="i"
        >
          <td
            v-for="field in dataFields.filter((f) => !f.includes(csvFilter))"
            :key="i + field"
            class="border border-solid border-level-standard py-2 pl-[12px] align-top first-of-type:pl-2 last-of-type:whitespace-pre-line last-of-type:pr-2"
            :class="{
              'w-[156px] font-bold': field === 'Company',
              'w-[112px]': field === 'Country',
              'w-[124px]': field === 'Tech Type',
            }"
          >
            {{ item[field as keyof Item] }}
          </td>
        </tr>
      </tbody>
    </table>
  </Container>
</template>
