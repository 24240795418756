export const updateFiltersChecked = (
  filtersChecked: { field: unknown; id: unknown }[],
  field: string,
  checked: boolean,
  id: string,
) => {
  const checkedIndex = filtersChecked.findIndex(
    (f) => f.field === field && f.id === id,
  );

  if (checkedIndex > -1 && !checked) {
    filtersChecked.splice(checkedIndex, 1);
  } else if (checked) {
    filtersChecked.push({ field, id });
  }
};

export const filterArray = <T extends Record<string, string | undefined>[]>(
  array: T,
  filters: { field: string; id: string }[],
): T => {
  if (filters.length === 0) return array;

  const proteins = filters.filter(
    (f) => f.field === "Main Protein Category" || f.field === "Protein Type",
  );
  const noRegions = !filters.find(
    (f) => f.field === "Region" || f.field === "Country",
  );

  return array.filter((item) => {
    let exists = false;
    for (const filter of filters) {
      if (filter.field.includes("Filter_")) {
        exists = item[filter.field]?.includes(filter.id) ?? false;
      } else if (
        (proteins.length === 0 || filter.field === "Company" || noRegions)
        && item[filter.field] === filter.id
      ) {
        exists = true;
        break;
      } else if (
        proteins.length > 0
        && (filter.field === "Region" || filter.field === "Country")
        && item[filter.field] === filter.id
      ) {
        let hasProtein = false;

        for (const protein of proteins) {
          hasProtein = item[protein.field] === protein.id;
          if (hasProtein) {
            exists = true;
            break;
          }
        }
      }
    }
    return exists;
  }) as T;
};

export const filterArrayDeep = <T extends Record<string, string>[]>(
  array: T,
  filtersChecked: { field: string; id: string }[],
  filterFields: string[],
): T => {
  let filteredArray = array;
  const filtersByField = filterFields.map((field) => ({
    field,
    filtersChecked: filtersChecked.filter((filter) => filter.field === field),
  }));

  for (const filter of filtersByField) {
    filteredArray = filterArray(filteredArray, filter.filtersChecked);
  }

  return filteredArray;
};
