<script lang="ts" setup>
defineProps<{ formEndpoint: string }>();

const emit = defineEmits(["sent"]);

const pending = ref(false);

const handle = async (event: Event) => {
  pending.value = true;

  const formData = new FormData(event.target as HTMLFormElement);

  try {
    const data = await $fetch("/api/pardot/submit", {
      method: "POST",
      body: Object.fromEntries(formData.entries()),
    });

    if (data === "ok") {
      emit("sent");
    } else {
      throw new Error(data);
    }
  } catch (error) {
    alert("Something went wrong, please try again later");

    throw error;
  }

  turnstile.value?.reset();

  pending.value = false;
};

const turnstile = ref();

const turnstileToken = ref("");
</script>

<template>
  <form @submit.prevent="handle">
    <input
      type="hidden"
      name="form_endpoint"
      :value="formEndpoint"
    />
    <div class="flex flex-col gap-3">
      <div class="form-floating">
        <select
          id="enquiry-type-input"
          name="enquiry_type"
          placeholder="Enquiry Type"
          aria-label="Enquiry Type"
          required
        >
          <option>General Enquiries</option>
          <option>Press Enquiries</option>
          <option>Investor Enquiries</option>
          <option>Career Enquiries</option>
          <option>Other</option>
        </select>
        <label for="enquiry-type-input">Enquiry Type</label>
      </div>
      <div class="form-floating">
        <input
          id="first-name-input"
          type="text"
          name="first_name"
          placeholder="First name"
          aria-label="First name"
          required
        />
        <label for="first-name-input">First name</label>
      </div>
      <div class="form-floating">
        <input
          id="last-name-input"
          type="text"
          name="last_name"
          placeholder="Last name"
          aria-label="Last name"
          required
        />
        <label for="last-name-input">Last name</label>
      </div>
      <div class="form-floating">
        <input
          id="email-address-input"
          type="email"
          name="email"
          placeholder="Email address"
          aria-label="Email address"
          required
        />
        <label for="email-address-input">Email address</label>
      </div>
      <div class="form-floating">
        <input
          id="organisation-input"
          type="text"
          name="organisation"
          placeholder="Organisation"
          aria-label="Organisation"
        />
        <label for="organisation-input">Organisation</label>
      </div>
      <div class="form-floating">
        <input
          id="subject-input"
          type="text"
          name="subject"
          placeholder="Subject"
          aria-label="Subject"
          required
        />
        <label for="subject-input">Subject</label>
      </div>
      <div class="form-floating">
        <textarea
          id="message-input"
          type="text"
          name="message"
          placeholder="Message"
          aria-label="Message"
          :style="{ height: '300px' }"
          required
        />
        <label for="message-input">Message</label>
      </div>
      <NuxtTurnstile
        ref="turnstile"
        v-model="turnstileToken"
        :options="{
          appearance: 'interaction-only',
        }"
        class="flex justify-center"
      />
      <div class="flex justify-center">
        <ClientOnly>
          <button
            class="btn btn-primary btn--icon btn--email"
            :disabled="!turnstileToken || pending"
          >
            Submit
          </button>
        </ClientOnly>
      </div>
    </div>
  </form>
</template>
