<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    items: string[];
    itemsTitles?: string[];
    initial?: string;
    label: string;
    placeholder?: string;
    selectNone?: boolean;
    id?: string;
  }>(),
  {
    items: () => [""],
    itemsTitles: () => [],
    initial: "",
    label: "",
    placeholder: "",
    selectNone: false,
    id: "",
  },
);

const selectedItem = ref<string>(props.initial ? props.initial : "");
const selectedIndex = ref<number>(
  props.initial ? props.items.indexOf(props.initial) : 0,
);
const selectedTitle = computed(() => {
  const title
    = props.itemsTitles.length > 1
      ? props.itemsTitles[selectedIndex.value]
      : selectedItem.value;
  return title === "" ? props.placeholder || "Please select" : title;
});

const open = ref<boolean>(false);

watch(
  () => props.items,
  () => {
    selectedItem.value = "";
    selectedIndex.value = 0;
  },
  { deep: true },
);

const emit = defineEmits(["click", "select"]);
const handleClick = () => {
  open.value = !open.value;
  emit("click", open.value);
};

const selectItem = (value: string, index: number) => {
  selectedItem.value = value;
  selectedIndex.value = index;
  emit("select", selectedItem.value, index);
};

const onClickOutside = () => (open.value = open.value ? false : open.value);
</script>

<template>
  <div
    :id="id"
    v-click-outside="onClickOutside"
    class="dropdown relative inline-block rounded-sm"
    :class="{ disabled: items.length === 1 && items[0] === '' }"
    @click="handleClick"
  >
    <div class="flex items-center justify-between">
      <div class="px-2 py-1">
        <label
          v-if="label"
          class="block text-xs font-semibold"
          :for="props.label"
        >{{ props.label }}</label>
        <div class="dropdown__selected-item">
          {{ selectedTitle }}
        </div>
      </div>
      <NuxtIcon
        name="fairr:arrow"
        size="20"
        class="me-2"
      />
    </div>

    <div
      class="dropdown__items absolute w-full py-1"
      :class="{ 'dropdown__items--hide': !open }"
    >
      <div
        v-if="selectNone && selectedItem"
        class="dropdown__item relative p-2"
        style="opacity: 0.6"
        @click="selectItem('', -1)"
      >
        Select none
      </div>
      <div
        v-for="(item, i) of items"
        :key="i"
        :data-index="i"
        class="dropdown__item relative p-2 font-semibold"
        :class="{
          'font-extrabold': item == selectedItem,
          'hidden': itemsTitles && itemsTitles[i] === '',
        }"
        @click="selectItem(item, i)"
      >
        {{ itemsTitles && itemsTitles.length > 1 ? itemsTitles[i] : item }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.dropdown {
  height: 47px;
  min-width: 160px;
  user-select: none;
  cursor: pointer;

  @apply border border-ui-grey2;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &__selected-item {
    margin-top: 1px;
  }

  &__items {
    @apply z-10 border border-ui-grey2 bg-white;

    top: 100%;

    &--hide {
      display: none;
    }
  }

  &__item {
    @apply bg-white;
  }
}
</style>
