export const sortByKey = <T>(array: T[], key: keyof T) => {
  const hasNA = array.find(
    (item) => item[key] === "N/A" || item[key] === "Undisclosed",
  );

  return array.sort(function (a, b) {
    if (hasNA) {
      if (a[key] === "N/A" || a[key] === "Undisclosed") return -1;
      if (b[key] === "N/A" || b[key] === "Undisclosed") return 1;
      return parseFloat(a[key] as string) - parseFloat(b[key] as string);
    }

    if (typeof a[key] !== typeof b[key]) {
      return typeof a[key] === "number" ? 1 : -1;
    }

    let x, y;

    if (typeof a[key] === "undefined" || typeof b[key] === "undefined") return -1;

    if (key === "date" || key === "Date") {
      x = new Date(String(a[key]).replace(/-(\d\d)$/, " 20$1"));
      y = new Date(String(b[key]).replace(/-(\d\d)$/, " 20$1"));
    } else if (typeof a[key] === "number" && typeof b[key] === "number") {
      const aN = Number(a[key]);
      const bN = Number(b[key]);
      return aN > bN ? 1 : aN < bN ? -1 : 0;
    } else {
      x = String(a[key]).toLowerCase();
      y = String(b[key]).toLowerCase();
    }

    if (x === y) return 0;
    if (x > y) return 1;
    return -1;
  });
};
