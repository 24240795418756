import type { Block, Inline } from "@contentful/rich-text-types";
// Use `yarn nuxi analyzer` to find components that should be lazy-loaded
import {
  HeaderInformation,
  HeaderCentral,
  HeaderStandard,
  HeaderVideo,
  LazyHeaderHomepage as HeaderHomepage,
  HeaderTheme,
  HeaderDownload,
  ModuleInlinePages,
  LazyTableDownloads as TableDownloads,
  TableCompanyInfo,
  BlockContact,
  BlockImageText,
  BlockLegal,
  BlockNewsletter,
  BlockQuote,
  BlockPromoColumns,
  LazyBlockVideo as BlockVideo,
  BlockStatistics,
  BlockSignatories,
  BlockStatement,
  BlockImageColumns,
  BlockHtml,
  BannerPromo,
  BannerVideoPromo,
  LazyToolClimateRisk as ToolClimateRisk,
  LazyToolCompanyRanking as ToolCompanyRanking,
  LazyToolCompanyComparison as ToolCompanyComparison,
  LazyCardBlockArticle as CardBlockArticle,
  LazyCardBlockEvent as CardBlockEvent,
  CardBlockInfo,
  CardBlockStaff,
  LazyCardBlockLogo as CardBlockLogo,
  CardBlockProject,
  LazyCarouselImageText as CarouselImageText,
  LazyCarouselColumn as CarouselColumn,
  LazyCarouselLogos as CarouselLogos,
  LazyCarouselVideo as CarouselVideo,
  FormLogin,
  LazyFormSignup as FormSignup,
  LazyOtherLottieScroll as OtherLottieScroll,
  NavigationColumns,
  ArticleBlockRight,
  ArticleComparison,
  BlockAccordion,
  BlockDoubleImagetext,
  NavigationSubpage,
  TableFilterableTable,
  TableSupplyChain,
  TableResolutions,
  BlockTitle,
} from "#components";

const componentMap = {
  headerInformation: HeaderInformation,
  headerVideo: HeaderVideo,
  headerCentral: HeaderCentral,
  headerStandard: HeaderStandard,
  headerHomepage: HeaderHomepage,
  headerTheme: HeaderTheme,
  headerDownload: HeaderDownload,
  moduleInlinePages: ModuleInlinePages,
  tableDownloads: TableDownloads,
  tableCompanyInfo: TableCompanyInfo,
  blockAccordion: BlockAccordion,
  blockContact: BlockContact,
  blockDoubleImagetext: BlockDoubleImagetext,
  blockQuote: BlockQuote,
  blockImageText: BlockImageText,
  blockLegal: BlockLegal,
  blockNewsletter: BlockNewsletter,
  blockPromoColumns: BlockPromoColumns,
  blockVideo: BlockVideo,
  blockSignatories: BlockSignatories,
  blockStatistics: BlockStatistics,
  blockStatement: BlockStatement,
  blockImageColumns: BlockImageColumns,
  blockHtml: BlockHtml,
  bannerPromo: BannerPromo,
  bannerVideoPromo: BannerVideoPromo,
  toolClimateRisk: ToolClimateRisk,
  toolCompanyRanking: ToolCompanyRanking,
  toolCompanyComparison: ToolCompanyComparison,
  cardBlockArticle: CardBlockArticle,
  cardBlockEvent: CardBlockEvent,
  cardBlockInfo: CardBlockInfo,
  cardBlockStaff: CardBlockStaff,
  cardBlockLogo: CardBlockLogo,
  cardBlockProject: CardBlockProject,
  carouselImageText: CarouselImageText,
  carouselLogos: CarouselLogos,
  carouselVideo: CarouselVideo,
  carouselColumn: CarouselColumn,
  formLogin: FormLogin,
  formSignup: FormSignup,
  lottieScroll: OtherLottieScroll,
  navigationColumns: NavigationColumns,
  articleBlockRight: ArticleBlockRight,
  articleBlockComparison: ArticleComparison,
  navigationSubpage: NavigationSubpage,
  tableFilterable: TableFilterableTable,
  tableSupplyChain: TableSupplyChain,
  tableResolutions: TableResolutions,
  blockTitle: BlockTitle,
};

export default (node: Block | Inline) => {
  if (
    !node.data.target
    || ("contentType" in node.data.target.sys
      && !node.data.target.sys.contentType)
  ) {
    return {};
  }

  const componentKey = String(node.data.target.sys.contentType.sys.id);

  const component = ((key?: string): key is keyof typeof componentMap =>
    !!key && key in componentMap)(componentKey)
    ? componentMap[componentKey]
    : undefined;

  return {
    component: component ?? componentKey,
    props:
      component && component.name !== "AsyncComponentWrapper"
        ? Object.fromEntries(
          Object.entries(node.data.target).filter(([key]) =>
            Object.keys("props" in component ? component.props : {}).includes(
              key,
            ),
          ),
        )
        : node.data.target,
  };
};
