import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";
import type { TypeActivitySkeleton } from "./TypeActivity";

export type TypeProjectFields = {
  name?: EntryFieldTypes.Symbol;
  shortTitle?: EntryFieldTypes.Symbol;
  type: EntryFieldTypes.Symbol<"Engagement" | "General" | "Policy" | "Research" | "Tool">;
  slug: EntryFieldTypes.Symbol;
  active?: EntryFieldTypes.Boolean;
  promo?: EntryFieldTypes.EntryLink<TypeActivitySkeleton>;
};

export type TypeProjectSkeleton = EntrySkeletonType<TypeProjectFields, "project">;
export type TypeProject<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypeProjectSkeleton, Modifiers, Locales>;

export function isTypeProject<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypeProject<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === "project";
}
