<script lang="ts" setup>
const props = defineProps<{
  items: string[];
  disabled?: boolean;
  unselectable?: boolean;
}>();

const selectedIndex = defineModel<number | null>();
selectedIndex.value = props.unselectable ? -1 : 0;

const emit = defineEmits(["select"]);

const onSelect = (index: number) => {
  console.log("onSelect", index, "selected", selectedIndex.value);

  if (index !== selectedIndex.value) {
    emit("select", true, props.items[index]);
    selectedIndex.value = index;
  } else if (props.unselectable) {
    selectedIndex.value = -1;
    emit("select", false, "");
  }
};
</script>

<template>
  <div
    class="radio pointer"
    :class="{ unselectable }"
  >
    <UiCheckbox
      v-for="(item, i) in items"
      :key="i"
      :name="item"
      :class="{ '!me-3': i !== items.length - 1 }"
      :checked="selectedIndex == i"
      :disabled="disabled"
      @click.prevent="onSelect(i)"
    >
      {{ item }}
    </UiCheckbox>
  </div>
</template>

<style lang="scss" scoped>
.radio {
  &:not(.unselectable):deep(.checkbox) {
    input[type="checkbox"] {
      pointer-events: none;
      border-radius: 50%;

      &::before {
        border-radius: 50%;
      }
    }

    &[disabled="true"] {
      pointer-events: none;

      input[type="checkbox"] {
        @apply border-ui-grey2;

        &::before {
          @apply bg-ui-grey2;

          transform: scale(1);
        }
      }
    }
  }
}
</style>
