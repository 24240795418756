<script lang="ts" setup>
import { ModalsContainer } from "vue-final-modal";
import "floating-vue/dist/style.css";

const metaTitleBase = useContentConfig().value?.metaTitleBase ?? "";

useSeoMeta({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} | ${metaTitleBase}` : metaTitleBase;
  },
});
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
    <ModalsContainer />
  </NuxtLayout>
</template>
