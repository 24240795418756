<script lang="ts" setup>
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import nodeRenderers from "~/lib/node-renderers.js";

const contentConfig = useContentConfig();
</script>

<template>
  <footer>
    <RichTextRenderer
      v-if="contentConfig?.footer"
      :document="contentConfig.footer"
      :node-renderers="nodeRenderers"
    />
  </footer>
</template>
