import type { TypeConfiguration } from "~/types/contentful";

export default defineNuxtRouteMiddleware(async () => {
  const nuxtApp = useNuxtApp();

  if (
    import.meta.client
      && (!nuxtApp.isHydrating || nuxtApp.payload.serverRendered)
  ) {
    return;
  }

  const { data: configData } = await useFetch<
    TypeConfiguration<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"]
  >("/api/config", { transform: useCircularProtect });

  useContentConfig().value = configData.value;
});
