import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";

export type TypeThemeFields = {
  title?: EntryFieldTypes.Symbol;
  colour: EntryFieldTypes.Symbol;
  slug?: EntryFieldTypes.Symbol;
};

export type TypeThemeSkeleton = EntrySkeletonType<TypeThemeFields, "theme">;
export type TypeTheme<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypeThemeSkeleton, Modifiers, Locales>;

export function isTypeTheme<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypeTheme<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === "theme";
}
