<script lang="ts" setup>
import type { TypeBlockImageColumns } from "~/types/contentful";

const props = defineProps<{
  fields: TypeBlockImageColumns<
    "WITHOUT_UNRESOLVABLE_LINKS",
    "en-GB"
  >["fields"];
}>();

const columnsNumber = computed(() => {
  switch (props.fields.images.length) {
    default:
    case 1:
      return "";
    case 2:
      return "lg:col-span-6";
    case 3:
      return "lg:col-span-4";
  }
});

const imageWidth = computed(() => {
  switch (props.fields.images.length) {
    default:
    case 1:
      return "1344";
    case 2:
      return "660";
    case 3:
      return "432";
  }
});
</script>

<template>
  <Container
    class="pb-4 md:pb-6"
    :class="{ 'py-4 md:pt-6': fields.displaySectionTitle }"
  >
    <div class="grid grid-cols-12 gap-4">
      <div
        v-if="fields.displaySectionTitle"
        class="col-span-12"
      >
        <TextSectionTitle>{{ fields.title }}</TextSectionTitle>
      </div>
      <div
        v-for="image in fields.images"
        :key="image?.sys.id"
        class="col-span-12"
        :class="columnsNumber"
      >
        <NuxtImg
          class="w-full"
          :src="image?.fields.file?.url"
          :alt="image?.fields.title"
          :width="imageWidth"
          loading="lazy"
        />
      </div>
    </div>
  </Container>
</template>
