export default defineNuxtRouteMiddleware((to, from) => {
  if (
    import.meta.client
      && from.fullPath !== to.fullPath
      && [/^\/cop26-investor-statement/].filter((regex) => to.fullPath.match(regex)).length
  ) {
    window.open(to.fullPath);

    return abortNavigation();
  }
});
