<script lang="ts" setup>
import type { EntryCollection } from "contentful";
import { formatDate } from "~/lib/date-time";
import type { TypeCompanyExtraSkeleton } from "~/types/TypeCompanyExtra";

const filterHqs = ref<string[]>([]);
const filterIndustries = ref<string[]>([]);
const filterProjects = ref<string[]>([]);

const input = ref<string>("");

const filterHqsParams = computed(() => {
  return filterHqs.value.join(",");
});

const filterIndustriesParams = computed(() => {
  return filterIndustries.value.join(",");
});

const filterProjectsParams = computed(() => {
  return filterProjects.value.join(",");
});

const { data: allCompanies } = await useLazyFetch<
  EntryCollection<
    TypeCompanyExtraSkeleton,
    "WITHOUT_UNRESOLVABLE_LINKS",
    "en-GB"
  >
>("/api/companies", { query: { perPage: 150 } });

const { data: filteredCompanies } = await useLazyFetch<
  EntryCollection<
    TypeCompanyExtraSkeleton,
    "WITHOUT_UNRESOLVABLE_LINKS",
    "en-GB"
  >
>("/api/companies", {
  query: {
    input,
    hq: filterHqsParams,
    industry: filterIndustriesParams,
    projectIds: filterProjectsParams,
    perPage: 150,
  },
});

const filteredCompaniesSorted = computed(() => [
  ...new Set(
    [...filteredCompanies.value?.items ?? []].sort((a, b) =>
      a.fields.name.localeCompare(b.fields.name),
    ),
  ),
]);

const hqs = computed(() =>
  [
    ...new Set(allCompanies.value?.items.map((company) => company.fields.hq)),
  ].filter((hq): hq is NonNullable<typeof hq> => !!hq),
);

const industries = computed(() =>
  [
    ...new Set(
      allCompanies.value?.items.map((company) => company.fields.industry),
    ),
  ].filter((industry): industry is NonNullable<typeof industry> => !!industry),
);

const projects = computed(() =>
  [
    ...new Map(
      allCompanies.value?.items
        .map((company) => company.fields.projects)
        .flat()
        .map((project) => [project?.sys.id, project]),
    ).values(),
  ].filter((project): project is NonNullable<typeof project> => !!project),
);

const filterPanelBlocks = computed(() => [
  [...hqs.value].sort(),
  [...industries.value].sort(),
  [
    ...projects.value,
  ].sort((a, b) =>
    (a.fields.name ?? "").localeCompare(b.fields.name ?? ""),
  ),
]);

const filters = computed(() => [
  filterHqs.value,
  filterIndustries.value,
  filterProjects.value,
]);

const onFilterChange = (checked: boolean, id: string, blockIndex: number) => {
  if (checked) {
    filters.value[blockIndex]?.push(id);
  } else {
    filters.value[blockIndex]?.splice(filters.value[blockIndex].indexOf(id), 1);
  }
};
</script>

<template>
  <Container class="table--company-info py-4 md:py-6">
    <div class="mt-4 flex flex-col justify-between md:flex-row">
      <div
        class="search mb-3 flex h-5 w-full items-center rounded-sm border border-ui-grey2 md:mb-0 md:w-[70%] md:max-w-[700px]"
      >
        <NuxtIcon
          class="mx-2"
          name="fairr:search"
          size="24"
        />
        <input
          v-model="input"
          type="text"
          placeholder="Search for..."
        />
      </div>
      <UiFilterPanel
        :titles="['HQ', 'Industry', 'Project']"
        :blocks="filterPanelBlocks"
        scroll-on-open
        @on-filter-change="onFilterChange"
      />
    </div>
    <div
      class="table-container mt-3"
      role="table"
      aria-label="Downloads"
    >
      <div
        class="flex-table header"
        role="rowgroup"
      >
        <div
          class="text-tag first flex-row"
          role="columnheader"
        >
          Company
        </div>
        <div
          class="text-tag hidden flex-row lg:block"
          role="columnheader"
        >
          Isin
        </div>
        <div
          class="text-tag hidden flex-row lg:block"
          role="columnheader"
        >
          Market Cap
        </div>
        <div
          class="text-tag hidden flex-row lg:block"
          role="columnheader"
        >
          HQ
        </div>
        <div
          class="text-tag hidden flex-row lg:block"
          role="columnheader"
        >
          Industry
        </div>
        <div
          class="text-tag hidden flex-row lg:block"
          role="columnheader"
        >
          Project
        </div>
        <div
          class="text-tag flex-row"
          role="columnheader"
        >
          Last assessed
        </div>
      </div>
      <div
        v-for="(company, index) in filteredCompaniesSorted"
        :key="index"
        class="flex-table py-3 md:py-0"
        role="rowgroup"
      >
        <div class="column">
          <div
            class="flex-row"
            role="rowgroup"
          >
            <div
              class="flex-cell first"
              role="cell"
            >
              <div class="flex flex-col items-start">
                <div class="text-sm font-semibold">
                  {{ company.fields.ticker }}
                </div>
                <div class="wysiwyg font-bold lg:text-sm">
                  <NuxtLink :to="getUrl(company)">
                    {{ company.fields.name }}
                    <NuxtIcon
                      class="ms-2"
                      name="fairr:link-out-mini"
                      size="24"
                    />
                  </NuxtLink>
                </div>
              </div>
            </div>
            <div
              class="flex-cell justify-start break-words"
              role="cell"
            >
              <div class="text-sm">
                {{ company.fields.isin }}
              </div>
            </div>
            <div
              class="flex-cell justify-end"
              role="cell"
            >
              <div
                v-if="company.fields.marketCap"
                class="me-2 text-sm"
              >
                ${{ company.fields.marketCap.toFixed(2) }}bn
              </div>
            </div>
            <div
              class="flex-cell"
              role="cell"
            >
              <div class="text-sm">
                {{ company.fields.hq }}
              </div>
            </div>
            <div
              class="flex-cell"
              role="cell"
            >
              <TextBadge class="badge--industry">
                {{ company.fields.industry }}
              </TextBadge>
            </div>
          </div>
        </div>
        <div class="column">
          <div
            class="last flex-row"
            role="cell"
          >
            <div
              class="flex-cell"
              role="cell"
            >
              <div class="flex flex-col items-start">
                <TextBadge
                  v-for="project in company.fields.projects"
                  :key="project?.fields.name"
                  class="my-1 !whitespace-normal"
                >
                  {{ project?.fields.name }}
                </TextBadge>
              </div>
            </div>
            <div
              class="flex-cell justify-end text-sm"
              role="cell"
            >
              {{
                company.fields.lastAssessed
                  ? formatDate(new Date(company.fields.lastAssessed))
                  : "-"
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </Container>
</template>

<style lang="scss" scoped>
.search {
  input {
    width: 100%;
    border: none;
  }
}

.flex-row {
  &:nth-child(1) {
    @apply w-[50%] lg:w-[20%];
  }

  &:nth-child(2) {
    @apply lg:w-[9%];
  }

  &:nth-child(3) {
    @apply lg:w-[9%];
  }

  &:nth-child(4) {
    @apply lg:w-[9%];
  }

  &:nth-child(5) {
    @apply lg:w-[16%];
  }

  &:nth-child(6) {
    @apply lg:w-[25%];
  }

  &:nth-child(7) {
    @apply w-[50%] lg:w-[12%];
  }
}

.column {
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  padding: 0;

  &:first-child {
    @apply w-[100%] md:w-[50%] lg:w-[63%];

    .flex-cell {
      &:nth-child(1) {
        @apply lg:w-[32%];
      }

      &:nth-child(2) {
        @apply lg:w-[15%];
      }

      &:nth-child(3) {
        @apply lg:w-[14%];
      }

      &:nth-child(4) {
        @apply lg:w-[14%];
      }

      &:nth-child(5) {
        @apply lg:w-[19%];
      }
    }
  }

  &:last-child {
    @apply w-[100%] md:w-[50%] md:ps-2 lg:w-[37%];

    .flex-cell {
      &:nth-child(1) {
        @apply lg:w-[66%];
      }

      &:nth-child(2) {
        @apply lg:w-[34%];
      }
    }
  }

  .flex-row {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    padding: 0;
    border: 0;

    @apply md:max-lg:my-[10px];
  }

  .flex-cell + .flex-cell {
    @apply lg:border-l-[10px] lg:border-l-white;
  }
}
</style>
