<script lang="ts" setup>
import { useSlots } from "vue";
import type { Asset } from "contentful";
import type { TypeDownload, TypeStaff } from "~/types/contentful";

const slots = useSlots();

defineProps<{
  downloads?: TypeDownload<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">[];
  contacts?: TypeStaff<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">[];
  image?: Asset<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">;
}>();
</script>

<template>
  <div
    class="header-info relative flex w-full flex-col gap-3 rounded border border-ui-grey2 bg-white p-4 text-dark-blue-grey-two"
  >
    <slot />

    <div
      v-if="slots['themetopic-badge']"
      class="flex items-center justify-between gap-2"
    >
      <div class="text-xs font-semibold">
        Theme:
      </div>
      <div>
        <slot name="themetopic-badge" />
      </div>
    </div>

    <div
      v-if="slots['industry-badge']"
      class="flex items-center justify-between gap-2"
    >
      <div class="text-xs font-semibold">
        Industry:
      </div>
      <div class="text-right">
        <slot name="industry-badge" />
      </div>
    </div>

    <div
      v-if="contacts && contacts.length"
      class="flex items-center justify-between gap-2"
    >
      <div class="text-xs font-semibold">
        Main Contact(s):
      </div>
      <div class="flex flex-wrap justify-end">
        <template
          v-for="contact in contacts"
          :key="contact.sys.id"
        >
          <TextLink
            v-if="contact.fields.currentEmployee"
            class="max-lg:ml-2"
            :href="getUrl(contact)"
            target="_blank"
          >
            {{ contact.fields.name }}
          </TextLink>
          <span
            v-else
            class="max-lg:ml-2"
          >{{ contact.fields.name }}</span>
        </template>
      </div>
    </div>

    <ReportCardImage
      v-if="image"
      :image="image"
    />

    <div>
      <hr class="hr-divider" />
      <hr class="hr-divider" />
      <hr class="hr-divider" />
    </div>

    <template
      v-for="download in downloads"
      :key="download.sys.id"
    >
      <UiDownloadLink
        :download="download"
        class-list="btn btn--icon btn--download w-full print:hidden"
      >
        {{ download.fields.shortTitle ?? download.fields.title }}
        <template #locked>
          <NuxtLink
            to="/login"
            class="btn btn--icon btn--locked w-full print:hidden"
          >
            {{ download.fields.shortTitle || download.fields.title }}
          </NuxtLink>
        </template>
      </UiDownloadLink>
    </template>
    <slot name="links" />
  </div>
</template>

<style lang="scss" scoped>
td {
  padding-top: 12px;
  padding-bottom: 12px;
}

td:first-child {
  padding-right: 8px;
  font-weight: bold;
}

td:last-child {
  text-align: right;
  padding-left: 8px;
}

tr:first-child td {
  padding-top: 0;
}
</style>
