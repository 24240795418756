import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";
import type { TypeArticleTypeSkeleton } from "./TypeArticleType";
import type { TypeDownloadSkeleton } from "./TypeDownload";
import type { TypeEventSkeleton } from "./TypeEvent";
import type { TypeExternalAuthorSkeleton } from "./TypeExternalAuthor";
import type { TypePageSkeleton } from "./TypePage";
import type { TypeReportSkeleton } from "./TypeReport";
import type { TypeStaffSkeleton } from "./TypeStaff";
import type { TypeThemeSkeleton } from "./TypeTheme";
import type { TypeTopicSkeleton } from "./TypeTopic";

export type TypeArticleFields = {
  title: EntryFieldTypes.Symbol;
  subtitle?: EntryFieldTypes.Symbol;
  slug?: EntryFieldTypes.Symbol;
  articleType?: EntryFieldTypes.EntryLink<TypeArticleTypeSkeleton>;
  parent?: EntryFieldTypes.EntryLink<TypePageSkeleton>;
  image?: EntryFieldTypes.AssetLink;
  videoDownload?: EntryFieldTypes.EntryLink<TypeDownloadSkeleton>;
  publishDate: EntryFieldTypes.Date;
  themeOrTopic?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeThemeSkeleton | TypeTopicSkeleton>>;
  authors?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeExternalAuthorSkeleton | TypeStaffSkeleton>>;
  content?: EntryFieldTypes.RichText;
  access?: EntryFieldTypes.Array<EntryFieldTypes.Symbol<"Journalist" | "Member" | "Public">>;
  relatedContent?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeArticleSkeleton | TypePageSkeleton | TypeReportSkeleton>>;
  event?: EntryFieldTypes.EntryLink<TypeEventSkeleton>;
  metaTitle?: EntryFieldTypes.Symbol;
  metaDescription?: EntryFieldTypes.Symbol;
};

export type TypeArticleSkeleton = EntrySkeletonType<TypeArticleFields, "article">;
export type TypeArticle<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypeArticleSkeleton, Modifiers, Locales>;

export function isTypeArticle<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypeArticle<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === "article";
}
