import type { Entry } from "contentful";
import type {
  TypeArticleSkeleton,
  TypeCompanySkeleton,
  TypePageSkeleton,
  TypeReportSkeleton,
  TypeStaffSkeleton,
} from "~/types/contentful";

export default <
  T = Entry<
    | TypeArticleSkeleton
    | TypeCompanySkeleton
    | TypePageSkeleton
    | TypeReportSkeleton
    | TypeStaffSkeleton,
    "WITHOUT_UNRESOLVABLE_LINKS",
    "en-GB"
  >,
>() => useState<T | null>("loadedContent", () => shallowRef(null));
