<script lang="ts" setup>
import type { TypeButton } from "~/types/TypeButton";
import type { TypeRepeater } from "~/types/TypeRepeater";
import {
  isTypeTheme,
  isTypeTopic,
  type TypeHeaderInformation,
} from "~/types/contentful";

defineProps<{
  fields: Omit<
    TypeHeaderInformation<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"],
    "industry"
  > & { industry?: string; links?: TypeButton[] };
}>();

const slots = useSlots();
</script>

<template>
  <Container
    class="header--information relative bg-dark-blue-grey-two py-4 text-white lg:border-x-[16px] lg:border-l-sapphire lg:border-r-dark-blue-grey-two lg:bg-center lg:before:absolute lg:before:left-2 lg:before:top-0 lg:before:z-10 lg:before:block lg:before:h-full lg:before:w-1 lg:before:bg-sapphire lg:before:content-['']"
  >
    <template #outside>
      <NuxtImg
        v-if="fields.backgroundImage?.fields.file?.url"
        class="absolute top-0 size-full object-cover object-[70%] max-md:object-[55%] lg:object-center"
        :src="fields.backgroundImage.fields.file.url"
        :alt="fields.backgroundImage.fields.title"
        sizes="sm:100vw md:100vw lg:100vw xl:100vw xxl:100vw"
        :height="600"
        fit="cover"
        loading="lazy"
      />
    </template>
    <HeaderBlockBreadcrumbs class="relative text-white" />
    <header
      class="relative grid-cols-12 gap-5 py-4 lg:grid lg:min-h-[450px] lg:py-5"
    >
      <div class="col-span-12 items-center lg:col-span-7 lg:flex">
        <div class="header-text mb-4 md:mb-5 lg:mb-0">
          <h1>{{ fields.title }}</h1>
          <div class="h2 mt-3">
            {{ fields.subtitle }}
          </div>
          <div
            v-if="fields.collaborators?.fields.file"
            class="mt-3 lg:mt-5"
          >
            <div class="mb-3 text-xs font-semibold">
              {{ fields.collaboratorCopy }}
            </div>
            <NuxtImg
              class="max-w-[200px]"
              :src="fields.collaborators.fields.file.url"
              :alt="fields.collaborators.fields.title"
              :width="200"
              loading="lazy"
            />
          </div>
        </div>
      </div>
      <div
        class="col-span-12 items-center lg:col-span-4 lg:col-start-9 lg:flex xl:col-span-3 xl:col-start-10"
      >
        <HeaderBlockInfo
          v-if="
            fields.contact || fields.downloads || fields.themetopic || slots
          "
          :contacts="
            fields.contact?.filter(
              (contact): contact is NonNullable<typeof contact> => !!contact,
            )
          "
          :downloads="
            fields.downloads?.filter(
              (download): download is NonNullable<typeof download> =>
                !!download,
            )
          "
        >
          <slot name="info-block" />

          <template
            v-if="fields.themetopic"
            #themetopic-badge
          >
            <NuxtLink
              :to="
                isTypeTheme(fields.themetopic)
                  ? `/themes/${fields.themetopic.fields.slug}`
                  : isTypeTopic(fields.themetopic)
                    && fields.themetopic.fields.theme?.fields.slug
                    ? `/themes/${fields.themetopic.fields.theme.fields.slug}`
                    : ''
              "
            >
              <TextBadge
                class="!whitespace-normal"
                :colour="
                  isTypeTheme(fields.themetopic)
                    ? fields.themetopic.fields.colour
                    : isTypeTopic(fields.themetopic)
                      ? fields.themetopic.fields.theme?.fields.colour
                      : undefined
                "
              >
                {{ fields.themetopic.fields.title }}
              </TextBadge>
            </NuxtLink>
          </template>

          <template
            v-if="fields.industrys"
            #industry-badge
          >
            <div
              v-for="(industry, index) in fields.industrys"
              :key="index"
            >
              <TextBadge class="badge--protein mb-1 w-max !whitespace-normal">
                {{ industry }}
              </TextBadge>
            </div>
          </template>

          <template #links>
            <NuxtLink
              v-for="link in fields.links"
              :key="link.title"
              :to="link.url"
              target="_blank"
              class="btn w-full"
            >
              {{ link.title }}
            </NuxtLink>

            <slot name="links" />
          </template>

          <template v-if="fields.extraDataPairs">
            <div
              v-for="pair in fields.extraDataPairs as unknown as TypeRepeater[]"
              :key="pair.key"
              class="flex items-center justify-between gap-2 text-xs"
            >
              <div class="font-semibold">
                {{ pair.key }}:
              </div>
              <div class="text-right">
                {{ pair.value }}
              </div>
            </div>
          </template>
        </HeaderBlockInfo>
      </div>
    </header>
  </Container>
</template>
