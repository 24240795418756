import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";

export type TypePageFields = {
  title: EntryFieldTypes.Symbol;
  slug?: EntryFieldTypes.Symbol;
  parent?: EntryFieldTypes.EntryLink<TypePageSkeleton>;
  access?: EntryFieldTypes.Array<EntryFieldTypes.Symbol<"Journalist" | "Member" | "Public">>;
  content?: EntryFieldTypes.RichText;
  excerpt?: EntryFieldTypes.Symbol;
  thumbnail?: EntryFieldTypes.AssetLink;
  metaTitle?: EntryFieldTypes.Symbol;
  metaDescription?: EntryFieldTypes.Symbol;
  hideMetaTitleBase?: EntryFieldTypes.Boolean;
  hideFromSearch?: EntryFieldTypes.Boolean;
  hideFromSitemap?: EntryFieldTypes.Boolean;
  useSimplifiedLayout?: EntryFieldTypes.Boolean;
};

export type TypePageSkeleton = EntrySkeletonType<TypePageFields, "page">;
export type TypePage<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypePageSkeleton, Modifiers, Locales>;

export function isTypePage<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypePage<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === "page";
}
