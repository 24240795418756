<script lang="ts" setup>
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import type { TypeBlockSignatories } from "~/types/contentful";

defineProps<{
  fields: TypeBlockSignatories<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
}>();
</script>

<template>
  <Container class="block-signatories py-4 md:py-6">
    <div class="grid grid-cols-12 gap-4">
      <div
        v-if="fields.displaySectionTitle"
        class="col-span-12"
      >
        <TextSectionTitle>{{ fields.title }}</TextSectionTitle>
      </div>

      <div class="wysiwyg col-span-12 md:col-span-7">
        <RichTextRenderer :document="fields.text" />
      </div>

      <div
        class="wysiwyg signatories col-span-12 md:grid md:grid-cols-2 lg:grid-cols-4"
      >
        <RichTextRenderer :document="fields.signatories" />
      </div>
    </div>
  </Container>
</template>

<style lang="scss" scoped>
.signatories:deep(p) {
  margin-bottom: 8px;
  margin-top: 8px;
  padding-right: 8px;
  min-height: 50px;
}
</style>
