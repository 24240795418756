import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";
import type { TypeThemeSkeleton } from "./TypeTheme";
import type { TypeTopicSkeleton } from "./TypeTopic";

export type TypeExternalPageFields = {
  title: EntryFieldTypes.Symbol;
  url: EntryFieldTypes.Symbol;
  themeOrTopic?: EntryFieldTypes.EntryLink<TypeThemeSkeleton | TypeTopicSkeleton>;
  subcopy?: EntryFieldTypes.Text;
  image?: EntryFieldTypes.AssetLink;
  date?: EntryFieldTypes.Date;
  type?: EntryFieldTypes.Symbol<"Insight" | "Press Release" | "Report">;
};

export type TypeExternalPageSkeleton = EntrySkeletonType<TypeExternalPageFields, "externalPage">;
export type TypeExternalPage<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypeExternalPageSkeleton, Modifiers, Locales>;

export function isTypeExternalPage<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypeExternalPage<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === "externalPage";
}
