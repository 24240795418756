<script lang="ts" setup>
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import nodeRenderers from "~/lib/node-renderers";
import type { TypeCardBlockStaff } from "~/types/contentful";

defineProps<{
  fields: TypeCardBlockStaff<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
}>();
</script>

<template>
  <Container class="card-block-staff py-4 md:py-6">
    <div class="grid grid-cols-12 gap-4">
      <div
        v-if="fields.displaySectionTitle"
        class="col-span-12"
      >
        <TextSectionTitle>{{ fields.title }}</TextSectionTitle>
      </div>

      <div
        v-if="fields.staff"
        class="staff-list col-span-12 grid gap-4 lg:grid-cols-2"
      >
        <div
          v-for="member in fields.staff.filter(
            (member): member is NonNullable<typeof member> => !!member,
          )"
          :key="member.sys.id"
          class="block cursor-pointer items-start border-t-2 border-ui-grey2 border-opacity-50 py-4 sm:flex"
          @click="() => navigateTo(getUrl(member))"
        >
          <div
            v-if="member.fields.thumbnail"
            class="w-1/3 min-w-[124px] pb-3 sm:order-2 sm:pb-0"
          >
            <NuxtImg
              v-if="member.fields.thumbnail.fields.file"
              :src="member.fields.thumbnail.fields.file.url"
              :alt="member.fields.thumbnail.fields.title"
              :width="224"
              :height="224"
              fit="contain"
              loading="lazy"
            />
          </div>

          <div class="order-1 sm:w-2/3">
            <TextBadge class="mb-3 max-w-[95%] !whitespace-normal">
              {{
                member.fields.jobTitle
              }}
            </TextBadge>
            <NuxtLink
              class="h3 block"
              :to="getUrl(member)"
            >
              {{
                member.fields.name
              }}
            </NuxtLink>
            <div class="wysiwyg pr-3 pt-3 text-xl font-normal">
              <RichTextRenderer
                v-if="member.fields.shortBio"
                :document="member.fields.shortBio"
                :node-renderers="nodeRenderers"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Container>
</template>
