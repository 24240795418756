<script lang="ts" setup>
const error = useError();

const contentConfig = useContentConfig();

const onSearchClick = () =>
  document.querySelector<HTMLElement>(".header input[type=search]")?.click();

const metaTitleBase = contentConfig.value?.metaTitleBase ?? "";

useHead({
  title: "Sorry",
});

useSeoMeta({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} | ${metaTitleBase}` : metaTitleBase;
  },
});
</script>

<template>
  <div v-if="error">
    <AppHeader />
    <main>
      <Container class="relative bg-dark-blue-grey-two py-4 text-white">
        <template #outside>
          <!-- eslint-disable-next-line tailwindcss/no-custom-classname -->
          <NuxtImg
            v-if="contentConfig?.errorBackgroundImage?.fields.file?.url"
            class="absolute top-0 size-full object-cover object-[70%] lg:object-center"
            :src="contentConfig.errorBackgroundImage.fields.file.url"
            sizes="sm:100vw md:100vw lg:100vw xl:100vw xxl:100vw"
            :height="600"
            fit="cover"
            loading="lazy"
          />
        </template>
        <header class="relative grid grid-cols-12 gap-4">
          <div
            class="col-span-12 items-center pt-4 text-center lg:col-span-6 lg:col-start-4 lg:py-5"
          >
            <h1>{{ error.statusCode }}</h1>
            <div class="h2 my-4">
              <template v-if="error.statusCode === 404">
                We are sorry, the page you are looking for cannot be found.
              </template>
              <template v-else>
                Sorry, something seems to have gone wrong, please try again
                later.
              </template>
            </div>

            

            <p
              v-if="error.statusCode === 404"
              class="mb-5"
            >
              Go back or try searching our website for its new location.
            </p>

            <div class="flex items-center justify-center gap-3">
              <a
                class="flex items-center gap-2 text-white"
                @click.prevent="
                  () => {
                    clearError();

                    $router.back();
                  }
                "
              >
                <NuxtIcon
                  name="button-arrow"
                  class="rotate-180 transition-transform hover:-translate-x-2 text-light-royal-blue"
                />
                Go Back
              </a>

              <div
                class="btn"
                @click.prevent="onSearchClick"
              >
                Search
              </div>
            </div>
          </div>
        </header>
      </Container>
    </main>
    <AppFooter />
  </div>
</template>
