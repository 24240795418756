import type { TypeUser } from "~/types/auth";

export default defineNuxtRouteMiddleware(async () => {
  const nuxtApp = useNuxtApp();

  // We must be running in the browser, and the page must be hydrating (page load)
  if (!import.meta.client || !nuxtApp.isHydrating) {
    return;
  }

  const { data: userData } = await useFetch<TypeUser | "">("/api/auth/user");

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  useUser().value = userData.value || null;
});
