import type { TypeArticle, TypeReport } from "~/types/contentful";

export default defineNuxtRouteMiddleware(async (to) => {
  if (to.path.startsWith("/article/")) {
    const { data: article } = await useFetch<
      TypeArticle<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">
    >("/api/article", {
      query: { slug: to.path.replace("/article/", "") },
    });

    if (article.value) {
      return navigateTo(getUrl(article.value), {
        redirectCode: 301,
      });
    } else {
      const { data: report } = await useFetch<
        TypeReport<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">
      >("/api/report", {
        query: { slug: to.path.replace("/article/", "") },
      });

      if (report.value) {
        return navigateTo(getUrl(report.value), {
          redirectCode: 301,
        });
      }
    }
  }
});
